import * as v from 'valibot';

export const initialValues = {
    gender: undefined,
    firstName: '',
    lastName: '',
    email: '',
    address: {
        street: '',
        postCode: '',
        city: '',
        countryCode: '',
    },
} as Purchaser;

export const schema = v.object({
    gender: v.config(
        v.picklist(['MALE', 'FEMALE', 'X']),
        { message: 'checkout.cart.purchaser.dialog.validationMessages.gender' },
    ),
    firstName: v.config(
        v.pipe(
            v.string(),
            v.nonEmpty(),
            v.maxLength(255),
            v.trim(),
        ),
        { message: 'checkout.cart.purchaser.dialog.validationMessages.name' },
    ),
    lastName: v.config(
        v.pipe(
            v.string(),
            v.nonEmpty(),
            v.maxLength(255),
            v.trim(),
        ),
        { message: 'checkout.cart.purchaser.dialog.validationMessages.name' },
    ),
    email: v.config(
        v.pipe(
            v.string(),
            v.nonEmpty(),
            v.maxLength(255),
            v.email(),
            v.trim(),
            v.toLowerCase(),

        ),
        { message: 'checkout.cart.purchaser.dialog.validationMessages.email' },
    ),
    address: v.object({
        street: v.config(
            v.pipe(
                v.string(),
                v.nonEmpty(),
                v.maxLength(255),
                v.trim(),
            ),
            { message: 'checkout.cart.purchaser.dialog.validationMessages.street' },
        ),
        postCode: v.config(
            v.pipe(
                v.string(),
                v.nonEmpty(),
                v.maxLength(255),
                v.trim(),
            ),
            { message: 'checkout.cart.purchaser.dialog.validationMessages.postCode' },
        ),
        city: v.config(
            v.pipe(
                v.string(),
                v.nonEmpty(),
                v.maxLength(255),
                v.trim(),
            ),
            { message: 'checkout.cart.purchaser.dialog.validationMessages.city' },
        ),
        countryCode: v.config(
            v.pipe(
                v.string(),
                v.nonEmpty(),
                v.length(2),
                v.trim(),
                v.toUpperCase(),
            ),
            { message: 'checkout.cart.purchaser.dialog.validationMessages.countryCode' },
        ),
    }),
});
