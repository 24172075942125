interface ResultType {
    quote: Quote | undefined;
    optionalOffers: OptionalOffer[];
}
type ApiResponseType = ApiSchema['Quote'];
type ParamsType = ApiSchema['BookingRequest'];

/**
 * Loads the quote for the given offers and passengers in the cart.
 * The quote contains the final price for all given offers combined.
 * In the cart context, the quote also contains optional offers.
 *
 * You can pass optional offers to this function, which will be included in the quote and determine the final price.
 * Do note that if you pass optional offers, they will not be returned in the quote! So to get a list of available
 * optional offers, you mustn't include the already selected ones in the same request.
 */
export default async function apiLoadCartQuote(offers: Offer[], passengers: Passenger[], optionalOffers: OptionalOffer[]): Promise<ApiResult<ResultType>> {
    try {
        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/quotes',

            // Request body
            {
                offers: [
                    ...offers.map(o => ({
                        offerId: o.offerId,
                        passengerRefs: o.passengerRefs,
                    })),
                    ...optionalOffers.map(o => ({
                        offerId: o.offerId,
                        passengerRefs: [],
                    })),
                ],
                passengerSpecifications: passengers.map(p => ({
                    externalRef: p.ref,
                    type: 'PERSON',
                    dateOfBirth: formatDateTimeISO(p.dateOfBirth),
                    cards: p.reductionCards ?? [],
                })),
            },
            // Transform result
            (response: ApiResponseType) => {
                const quote = {
                    price: response.price,
                };

                const optionalOffers = response.additionalOptionalOffersBasedOnSelection?.map<OptionalOffer>((o) => {
                    const offer: OptionalOffer = {} as OptionalOffer;

                    if (o.ancillaryOfferParts?.[0]) {
                        const part = o.ancillaryOfferParts?.[0];
                        if (part.type === 'INSURANCE') {
                            offer.offerId = o.offerId;
                            offer.offerRef = part.type;
                            offer.price = part.price;
                            offer.type = 'insurance';
                            offer.name = part.summary ?? '';
                            offer.info = {
                                heading: part.summary ?? '',
                                body: part.description ?? '',
                            };
                        }
                    }

                    return offer;
                }) ?? [];

                return {
                    quote,
                    optionalOffers,
                };
            },
        );
    }
    catch (error) {
        handleError(error);
        return {
            ok: false,
            warnings: [],
            data: {
                quote: undefined,
                optionalOffers: [],
            },
        };
    }
}
